import React from "react"
import DocumentsSectionFirst from "./documentsSections/documentsSectionFirst"

const sections = [{ component: (key: number, className: string, data: any) => <DocumentsSectionFirst data={data} className={className} key={key} /> }]

const DocumentsSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-sitemap  sova-section-sitemap_${i}`, data))}</>
}

DocumentsSections.displayName = "DocumentsSections"

export default DocumentsSections
