import React from "react"
import Section from "../../../components/section/Section"
import DownLoadLink from "../../../components/downloadLink/DownloadLink"

interface SitemapMenuItem {
  item: string
  link: string
  value: string | null
}

interface DocumentsMenu {
  documentsMenuHeading: string
  documentsMenuItems: SitemapMenuItem[]
}

const DocumentsSectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { heading, documentsMenu } = data.data.content.DocumentsSectionFirstConfig
  const { lang } = data.data.pageContext

  return (
    <Section className={className}>
      <h2 className="sova-h2 sova-h2_sitemap">{heading}</h2>
      <div className="sova-section-container sova-section-container_documents-1">
        {documentsMenu.map(({ documentsMenuHeading, documentsMenuItems }: DocumentsMenu, key: number) => {
          return (
            <div key={key} className={`sova-section-child-container sova-section-child-container_row sova-section-child-container_sitemap sova-section-child-container_sitemap-${key}`}>
              <div className={`sova-section-sitemap-decorative-element sova-section-sitemap-decorative-element-${key}`}></div>
              <div className="sova-section-container sova-section-container_sitemap-menu-1">
                <h5 className="sova-h5">{documentsMenuHeading}</h5>
                <ul className="sova-section-column sova-section-container_sitemap-menuItems-1">
                  {documentsMenuItems.map(({ item, value }, key) => {
                    return value ? (
                      <DownLoadLink key={key} name={item} lang={lang} value={value} className="sova-section-child sova-section-child_sitemap-1-menuItem sova-p-tiny" />
                    ) : (
                      <span key={key} className="sova-section-child sova-section-child_sitemap-1-menuItem sova-p-tiny">
                        {item}
                      </span>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </div>
    </Section>
  )
}

DocumentsSectionFirst.displayName = "DocumentsSectionFirst"

export default DocumentsSectionFirst
