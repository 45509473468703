import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../components/SEO/seo"
import DocumentsSections from "../pagesStructures/documents/documentsSections"

const Documents = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiDocuments.content[0]
  return (
    <Layout bgCustomClass="sitemap">
      <SEO title={pageContent.content.title} />
      <DocumentsSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default Documents
export const query = graphql`
  query Documents($url: String) {
    strapiDocuments(url: { eq: $url }) {
      content {
        language
        content {
          title
          DocumentsSectionFirstConfig {
            heading
            documentsMenu {
              documentsMenuHeading
              documentsMenuItems {
                item
                link
                value
              }
            }
          }
        }
      }
    }
  }
`
